import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Slider } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "slider"
    }}>{`Slider`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Slider from '@mfrm/mfcl/Slider'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Slider} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component\n  initialState={{ values: [4], labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }}\n>\n  {({ setState, state }) => (\n    <Slider\n      onChange={values => {\n        setState({ values })\n      }}\n      labels={state.labels}\n      values={state.values}\n      max={state.labels.length - 1}\n    />\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Slider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        values: [4],
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <Slider onChange={values => {
          setState({
            values
          });
        }} labels={state.labels} values={state.values} max={state.labels.length - 1} mdxType="Slider" />}
  </Component>
    </Playground>
    <h2 {...{
      "id": "multiple-values"
    }}>{`Multiple Values`}</h2>
    <Playground __position={2} __code={'<Component\n  initialState={{\n    values: [1, 3],\n    labels: [\'$100\', \'$1,000\', \'$2,000\', \'$4,000\', \'$8,000\'],\n  }}\n>\n  {({ setState, state }) => (\n    <Slider\n      onChange={values => {\n        setState({ values })\n      }}\n      onChangeCommited={value => {\n        console.log(\'Change commited. Send to API\')\n      }}\n      labels={state.labels}\n      values={state.values}\n      max={state.labels.length - 1}\n    />\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Slider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        values: [1, 3],
        labels: ['$100', '$1,000', '$2,000', '$4,000', '$8,000']
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <Slider onChange={values => {
          setState({
            values
          });
        }} onChangeCommited={value => {
          console.log('Change commited. Send to API');
        }} labels={state.labels} values={state.values} max={state.labels.length - 1} mdxType="Slider" />}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      